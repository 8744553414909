h3{
  text-align:center;
}
.centered{
  text-align:center;
  list-style-type: none;
}
.centeredImg{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 5%;
}
li{
  color: white;
  font-size: 20px;
}
p{
  text-align:center;
}
#Number {
  width:40px;
  position: fixed;
  top: 16.2%;
  right: 20%;
}

#NumberInput{
  width: 33px;
}

.upDown{
  font-size: 10px;
  width: 20px;
}
#numberText {
  width: 200px;
  text-align: justify;
}